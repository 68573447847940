<template>
  <v-row class="mt-3" no-gutters>
    <v-col cols="4" class="pr-1">
      <div class="mb-4">
        <v-row no-gutters class="mb-2">
          <v-col cols="5">
            <ExcelReader
              label="엑셀 업로드"
              block
              :readExcel.sync="excelData"
            ></ExcelReader>
          </v-col>
          <v-col cols="2"> </v-col>
          <v-col cols="5">
            <v-btn block large dark @click="excelData = null">
              목록 초기화
            </v-btn>
          </v-col>
        </v-row>

        <p class="font-size--16 font-weight-bold my-1 red--text">
          - A열은 반드시 유저인덱스여야 합니다
        </p>
        <p class="font-size--14 mb-0">
          - B열부터 변수이며 총 4개까지 설정할 수 있습니다
        </p>
      </div>
      <div>
        <hr class="width-100 my-5" style="border-top-width: 3px" />

        <UserList
          v-if="addSearch"
          @selectUser="(v) => addUserList(v)"
          shortcut
        />
      </div>
    </v-col>

    <v-col cols="8" class="pl-1">
      <v-data-table
        :headers="variables"
        :items="receiverList"
        :items-per-page="30"
        hide-default-header
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                v-for="(variable, index) in headers"
                :key="`variable_${index}`"
                width="120px"
                class="text-center"
              >
                <p class="font-size--15 my-0">
                  {{ index === 0 ? '유저인덱스' : variables[index] }}
                </p>
              </th>
            </tr>
          </thead>
        </template>

        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="receiver in items"
              :key="receiver[primaryKey]"
              @click="selectedReceiver = receiver"
            >
              <td
                v-for="k in variables"
                class="text-center"
                :key="`${receiver[primaryKey]}_${k}`"
              >
                {{ receiver[k] }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ExcelReader from '@/components/common/datatable/ExcelReader.vue'

export default {
  props: {
    addSearch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ExcelReader,
    UserList: () => import('@/components/funda/user/List.vue'),
  },
  data() {
    return {
      excelData: null,
      users: [],
    }
  },
  computed: {
    variables() {
      let temp = this.excelData?.header || [
        '유저인덱스',
        '변수1',
        '변수2',
        '변수3',
        '변수4',
      ]
      temp = temp.slice(0, 5)
      if (temp[0] === undefined) {
        temp[0] = '유저인덱스'
      }

      this.$emit('update:excelHeader', temp)
      return temp
    },
    primaryKey() {
      return this.variables[0]
    },
    receiverList() {
      let excelValues = this.excelData?.data || []
      excelValues = excelValues.reduce((result, value) => {
        const isInclude = result.some((r) => {
          return r[this.primaryKey] === value[this.primaryKey]
        })

        if (isInclude === false) {
          result.push(value)
        }

        return result
      }, [])

      const result = [...this.users, ...excelValues]
      this.$emit('update:excelTable', result)
      return result
    },
  },
  watch: {
    variables() {
      this.users = []
    },
  },
  methods: {
    addUserList(user) {
      if (!!user === false) {
        return
      }

      const has = this.users.some((u) => {
        return u[this.primaryKey] === user.idx
      })

      if (has === false && user) {
        const temp = {}

        this.variables.forEach((v, i) => {
          if (i === 0) {
            temp[v] = user.idx
          } else {
            temp[v] = null
          }
        })
        this.users.push(temp)
      }
    },
  },
}
</script>
